
import { Options, Vue } from 'vue-class-component'
import Reader from './components/Reader.vue'
import DarkModeButton from './components/DarkModeButton.vue'


@Options({
  components: {
    Reader,
    DarkModeButton
  },
})
export default class App extends Vue {
  darkmode = false
}
