
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
  }
})
export default class Reader extends Vue {
  wordsEntered = false
  enteredtext = `Paste text here to read it quickly`

  read(): void {
    this.words = []
    let tempWords = this.enteredtext.split(/[\s\n]/)
    for (let i = 0; i < tempWords.length; i++) {
      if (/\w+/.test(tempWords[i])) {
        this.words.push(tempWords[i])
      }
    }
    this.wordsEntered = true
  }

  edit(): void {
    this.pause()
    this.wordsEntered = false
  }
  
  playing = false

  playLoop(): void {
    if (this.wordIndex >= this.words.length) {
      this.playing = false
      this.wordIndex = 0
    }
    if (this.playing) {
      this.wordIndex++
      setTimeout(this.playLoop, 1000/(this.speed/60))
    }
  }

  play(): void {
    this.playing = true
    this.playLoop()
  }

  pause(): void {
    this.playing = false
  }

  words: string[] = []
  wordIndex = 0
  get word(): string {
    return this.words[this.wordIndex]
  }

  speed = 600

  minSecs(seconds_in: number):string {
    let minutes = new String(Math.floor(seconds_in/60))
    let seconds = new String(Math.round(seconds_in - 60*Math.floor(seconds_in/60)))
    return minutes + ":" + (Number(seconds) < 10 ? "0" : "") + seconds
  }

  get timeElapsed(): string {
    let time = Math.round(this.wordIndex / (this.speed / 60))
    return this.minSecs(time) 
  }
  get totalTime(): string {
    let time = (this.words.length / (this.speed / 60))
    return this.minSecs(time)
  }
  get timeLeft(): string {
    let time = ((this.words.length - this.wordIndex) / (this.speed / 60))
    return this.minSecs(time)
  }

  mounted(): void {
    document.addEventListener('keydown', (e) => {
      switch (e.key) {
        case ("ArrowUp"):
          this.speed += 25
          break
        case ("ArrowDown"):
          this.speed -= 25
          break
        case ("ArrowLeft"):
          this.wordIndex--
          break
        case ("ArrowRight"):
          this.wordIndex++
          break
        case (" "):
          if (this.playing) {
            this.pause()
          }
          else {
            this.play()
          }
          break
        case ("r"):
          this.wordIndex = 0
          break
      }
    })
  }
}
