import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DarkModeButton = _resolveComponent("DarkModeButton")!
  const _component_Reader = _resolveComponent("Reader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DarkModeButton),
    _createVNode(_component_Reader, { id: "reader-main" })
  ], 64))
}