
import { Vue } from 'vue-class-component';

export default class App extends Vue {
    theme = 'light'

    mounted(): void {
        const initUserTheme = this.getPreferred();
        this.setTheme(initUserTheme);
    }

    toggleTheme(): void {
        const activeTheme = localStorage.getItem("theme");
        this.setTheme(activeTheme === "light" ? "light" : "dark")
        if (activeTheme === "light") {
            this.setTheme("dark");
        }
        else {
            this.setTheme("light");
        }
        
    }

    setTheme(theme: string): void {
        localStorage.setItem("theme", theme);
        this.theme = theme;
        document.documentElement.className = theme;
    }

    getPreferred(): string {
        const hasDarkPreference = window.matchMedia(
            "(prefers-color-scheme: dark)"
        ).matches;
        if (hasDarkPreference) {
            return "dark";
        } 
        else {
            return "light";
        }
    }
}
